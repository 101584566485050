@import "/srv/www/assets/styles/_mixins.scss";

.pagination {
    ul > li > a {
        @apply px-4 py-2;
    }

    .current-page {
        @apply bg-gray rounded;
    }
}
