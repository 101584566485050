
.fade-enter-active,
.fade-leave-active {
    @apply transition-opacity duration-300;
}

.fade-enter-from,
.fade-leave-to {
    @apply opacity-0;
}

div {
    &.loading {
        @apply relative overflow-hidden;

        > * {
            @apply filter blur-sm;
        }

        &::after {
            @apply block absolute left-0 top-0 w-full h-full bg-gray-darkest opacity-10;

            content: " ";
        }
    }
}

.btn {
    @apply flex flex-row items-center justify-center gap-1 transition-all duration-300 outline-none rounded
    py-1 px-2 border font-bold uppercase text-sm sm:text-base sm:h-[50px] sm:p-[20px] sm:text-[17px];

    &.btn-sm {
        @apply normal-case text-sm py-0 px-1;
    }
    &.btn-secondary:not(:disabled) {
        @apply border-gray-dark border-opacity-50 bg-gray-dark hover:bg-gray-darker text-white hover:text-white;

        &.btn-outline {
            @apply hover:bg-gray-dark text-gray-darkest;
        }
    }

    &.btn-primary:not(:disabled) {
        @apply border-blue bg-blue hover:bg-blue-dark text-white;

        &.btn-outline {
            @apply hover:bg-blue text-blue hover:text-white;
        }

        &.btn-link {
            @apply bg-transparent border-transparent text-blue hover:text-blue-light;
        }
    }

    &.btn-success:not(:disabled) {
        @apply border-green bg-green hover:bg-green-dark text-white;

        &.btn-outline {
            @apply hover:bg-green text-green hover:text-white;
        }

        &.btn-link {
            @apply bg-transparent border-transparent text-blue hover:text-blue-light;
        }
    }

    &.btn-danger:not(:disabled) {
        @apply border-red bg-red hover:bg-red-dark text-white;

        &.btn-outline {
            @apply hover:bg-red text-red hover:text-white;
        }

        &.btn-link {
            @apply bg-transparent border-transparent text-red hover:text-red-light;
        }
    }

    &.btn-warning:not(:disabled) {
        @apply border-orange bg-orange hover:bg-orange-dark text-gray-darkest hover:text-white;

        &.btn-outline {
            @apply hover:bg-orange-light text-orange hover:text-gray-darkest;
        }

        &.btn-link {
            @apply bg-transparent border-transparent text-orange hover:text-orange-light;
        }
    }

    &.btn-info:not(:disabled) {
        @apply border-blue-light bg-blue-light hover:bg-blue text-white;

        &.btn-outline {
            @apply hover:bg-blue-light text-blue-light hover:text-gray-darkest;
        }

        &.btn-link {
            @apply bg-transparent border-transparent text-blue-light hover:text-blue-dark;
        }
    }

    &.btn-link {
        @apply border-transparent bg-transparent;

        &:not(:disabled) {
            @apply text-blue hover:text-blue-dark;
        }
    }

    &.btn-outline,
    &.btn-outline:not(:disabled) {
        @apply border-2 bg-white;
    }

    &.btn-neutral {
        @apply border-gray bg-gray hover:bg-gray-dark hover:border-gray-dark text-gray-darkest;
    }

    &.btn-cart {
        @apply rounded-none text-2xl p-1 px-5 h-full;
    }

    &:disabled {
        @apply opacity-50;
    }
}

.btn-group {
    @apply flex flex-row items-center justify-center gap-0;

    .btn:first-child {
        @apply rounded-r-none;
    }

    .btn:last-child {
        @apply rounded-l-none;
    }
}

.text-shadow-white {
    text-shadow: 0 0 3px #fff;
}

.text-shadow-black {
    text-shadow: 0 0 3px #000;
}

.filters {
    @apply bg-white border-b-2 border-gray-dark p-4 flex flex-col lg:flex-row lg:text-sm gap-2;
}

.additional-filters {
    @apply bg-white border-b-2 border-gray-dark grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 p-4 gap-2 lg:text-sm;
}

.filters,
.additional-filters,
.additional-filters-close-btn {
    @apply duration-300 ease-out transition-all;

    &.closed {
        @apply h-0 py-0 overflow-hidden;
    }
}

.input-group {
    @apply flex flex-row gap-0 justify-center items-stretch content-center w-full;

    > :not(:last-child) {
        @apply rounded-r-none;

        input {
            @apply rounded-r-none;
        }
    }

    > :not(:first-child) {
        @apply rounded-l-none;

        input {
            @apply rounded-l-none;
        }
    }
}

.discount-theme {
    border-top: 0;
    background-color: #686868;
    color: #fff;
    //box-shadow: rgba(104, 104, 104, 0.4) 0px -5px, rgba(104, 104, 104, 0.3) 0px -10px, rgba(104, 104, 104, 0.2) 0px -15px, rgba(104, 104, 104, 0.1) 0px -20px, rgba(104, 104, 104, 0.05) 0px -25px;
    > td {
        grid: none !important;
    }
    &.discount-theme-0 {
        background-color: #686868;
    }
    &.discount-theme-1 {
        background-color: #20c25b;
        //box-shadow: rgba(32, 194, 91, 0.4) 0px -5px, rgba(32, 194, 91, 0.3) 0px -10px, rgba(32, 194, 91, 0.2) 0px -15px, rgba(32, 194, 91, 0.1) 0px -20px, rgba(32, 194, 91, 0.05) 0px -25px;
    }
    &.discount-theme-2 {
        background-color: #ffbb00;
        //box-shadow: rgba(255, 187, 0, 0.4) 0px -5px, rgba(255, 187, 0, 0.3) 0px -10px, rgba(255, 187, 0, 0.2) 0px -15px, rgba(255, 187, 0, 0.1) 0px -20px, rgba(255, 187, 0, 0.05) 0px -25px;
    }
    &.discount-theme-3 {
        background-color: #ff5e66;
        //box-shadow: rgba(255, 94, 102, 0.4) 0px -5px, rgba(255, 94, 102, 0.3) 0px -10px, rgba(255, 94, 102, 0.2) 0px -15px, rgba(255, 94, 102, 0.1) 0px -20px, rgba(255, 94, 102, 0.05) 0px -25px;
    }
}

.button-orderable.active::after,
.button-limited.active::after {
    @apply absolute block bg-gray-darker text-white opacity-95 w-80 p-4 -bottom-8 -right-8 -z--1;
    content: var(--tooltip-limited);
}
.button-orderable.active::after {
    content: var(--tooltip-orderable);
}
@media (pointer: fine) {
    .button-orderable > .tooltip:hover::after,
    .button-limited > .tooltip:hover::after {
        @apply absolute block bg-gray-darker text-white opacity-95 w-80 p-4 -bottom-8 -right-8 -z--1;
        content: var(--tooltip-limited);
    }
    .button-orderable > .tooltip:hover::after {
        content: var(--tooltip-orderable);
    }
}

.textbox {
    @apply relative cursor-pointer
}

.textbox:before {
    content: attr(data-text); /* here's the magic */
    @apply bg-gray-darker text-white opacity-95 p-4 absolute text-center hidden left-full top-1/2
    -translate-y-1/2 whitespace-pre;
}

.textbox:hover:before {
    @apply block
}

.textbox-left:before {
    @apply right-full mr-2;
    left:initial;
    margin:initial;
}

.textbox-right:before {
    @apply left-full ml-2;
    right:initial;
    margin:initial;
}

.coupon-state {
    .active_waiting {
        @apply bg-purple-500;
    }

    .active_new {
        @apply bg-green;
    }

    .active_partially_used {
        @apply bg-blue;
    }

    .archived_full_used {
        @apply bg-gray-darker;
    }

    .archived_partially_used {
        @apply bg-orange;
    }

    .archived_not_used {
        @apply bg-red;
    }
}

.show-popup-message {
    position: relative;
    &::before {
        @apply absolute block bottom-full
            opacity-90 rounded-lg p-4 py-2
            content-[attr(data-popup-message)]
            text-white text-xs
            whitespace-nowrap
        ;
    }
    &::after {
        @apply absolute block top-0
            opacity-90
            h-0 w-0 border-8 border-transparent
            content-['']
        ;
    }
    // default state is danger
    &::before {
        @apply bg-red;
    }
    &::after {
        @apply border-t-red;
    }
    // warning state
    &.warning::before {
        @apply bg-orange;
    }
    &.warning::after {
        @apply border-t-orange;
    }
    // info state
    &.info::before {
        @apply bg-blue;
    }
    &.info::after {
        @apply border-t-blue;
    }
}

;@import "sass-embedded-legacy-load-done:8";