<template>
    <div class="grid">
        <button
            class="btn relative"
            :class="[{'confirmation': isConfirmed}, buttonClasses]"
            @click="handleClick"
            :data-message="message"
        >
            <slot><i class="text-2xl marso-icon-trash"></i></slot>
        </button>
    </div>
</template>

<script setup lang="ts">
import {ref, Ref} from "vue";

const isConfirmed: Ref<boolean> = ref(false);
const emit = defineEmits(['confirmed']);

const props = defineProps({
    message: {
        type: String,
        required: false
    },
    buttonClasses: {
        type: String,
        required: false,
        default: 'btn-danger btn-link'
    }
});

const handleClick = () => {
    if (isConfirmed.value) {
        emit('confirmed');
        isConfirmed.value = false;
        return;
    }

    isConfirmed.value = true;
    setTimeout(() => {
        isConfirmed.value = false;
    }, 5000);
}

</script>

<style scoped>
.confirmation::before {
    position: absolute;
    display: block;
    content: attr(data-message);
    opacity: 0.9;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    white-space: nowrap;
    top: -100%;
    @apply bg-red
}

.confirmation::after {
    content: "";
    position: absolute;
    display: block;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    top: -5px;
    opacity: 0.9;
    @apply border-t-red
}

</style>
