@import "/srv/www/assets/styles/_mixins.scss";

label.toggle {
    @apply relative;

    &.disabled::after {
        @apply block absolute left-0 top-0 w-full h-full bg-white opacity-70;

        content: " ";
    }
}
