<template>
    <div v-if="'PREPARED' == shipmentStore.shipment.status">
        <transition-group
            name="stop-point"
            tag="div"
            class="drop-zone flex flex-col gap-4 pb-4 pt-4"
            @drop="onStopPointDrop($event)"
            @dragenter.prevent
            @dragover.prevent
        >
            <div v-for="stopPoint in shipmentStore.orderedStopPoints" :id="stopPoint.id"
                 class="drop-zone grid grid-cols-[auto,1fr,1fr] bg-white p-4 gap-4 cursor-grab"
                 draggable="true"
                 @dragstart="onStopPointDragStart($event, stopPoint)"
                 @drop.stop.prevent="onStopPointDropToItem($event)"
                 :class="draggingId == stopPoint.id ? 'dragging shadow-sm' : ''"
                 :data-sequence-number="stopPoint.sequenceNumber"
                 :data-id="stopPoint.id"
                 :key="stopPoint.id"
            >
                <div class="text-center text-xl font-bold w-8">{{ stopPoint.sequenceNumber }}</div>
                <div class="font-bold">{{ stopPoint.deliveryAddress }}</div>
                <div>{{ getPackingSlipIds(stopPoint.deliveryAddress) }}</div>
            </div>
        </transition-group>
    </div>
    <div class="flex gap-4 justify-center">
        <button v-if="'PREPARED' == shipmentStore.shipment.status"
                type="button"
                class="btn btn-secondary w-auto md:h-full"
                :disabled="!needToSave"
                @click="shipmentOrderingFinished()"
        >
            {{ trans('shipment_ordering_save_button', 'Save the order', 'shipment') }}
        </button>
        <button v-if="'PREPARED' == shipmentStore.shipment.status"
                type="button"
                class="btn btn-success w-auto md:h-full"
                @click="shipmentOrderingFinished(true)"
        >
            {{ trans('shipment_ordering_finished_button', 'Ordering finished', 'shipment') }}
        </button>
    </div>
</template>

<script setup lang="ts">
import {Ref, ref, onBeforeMount, onUnmounted} from "vue";
import FieldDefinitionInterface from "../../components/ResponsiveTable";
import {useShipmentStore} from "../../stores/shipment/shipmentStore";
import {PackingSlipInterface} from "../../models/PackingSlip";
import {useRouter} from 'vue-router';
import {useToastStore} from "../../stores/toast/toastStore";
import {ShipmentStatus} from "../../models/ShipmentStatus";
import {PackingSlipStatus} from "../../models/PackingSlipStatus";
import {trans} from "../../common/i18n";
import { ShipmentInterface } from "../../models/Shipment";
import { StopPointInterface } from "../../models/StopPoints";

const shipmentStore = useShipmentStore();
let draggingId: Ref<number> = ref(0);
const needToSave: Ref<boolean> = ref(false);
const router = useRouter();
const toastStore = useToastStore();

const packingSlipListFields: Ref<FieldDefinitionInterface[]> = ref<FieldDefinitionInterface[]>([
    {
        key: "sequenceNumber",
        label: trans('sequence_number', "Sequence", 'shipment') ?? "Sequence",
        value: "sequenceNumber",
    },
    {
        key: "id",
        label: trans('id', "ID", 'shipment') ?? "ID",
        value: "id",
    },
    {
        key: "customerName",
        label: trans('customer_name', "Customer Name", 'shipment') ?? "Customer Name",
        value: "customerName",
    },
    {
        key: "customerAddress",
        label: trans('customer_address', "Customer Address", 'shipment') ?? "Customer Address",
        value: "customerAddress",
    },
    {
        key: "cashOnDeliveryPrice",
        label: trans('cash_on_delivery_price', "Cash on delivery price", 'shipment') ?? "Cash on delivery price",
        value: "cashOnDeliveryPrice",
    },
]);

onBeforeMount(async () => {
    await shipmentStore.getStopPointsByShipment(router, { shipmentId: shipmentStore.shipment.id });

    // le kell ellenorizni a sorrendiseget, hogy mindenhol jo ertekek legyenek
    // tehat ne legyen sehol 0, es ne legyen 2 egyforma szam sem
    // sorbarendezzuk, igy biztosan tudhatjuk, hogy a nulla az elso
    let stopPointsList = shipmentStore.orderedStopPoints;
    let stopPointsListUnordered: StopPointInterface[] = [];
    let stopPointsListReordered: StopPointInterface[] = [];
    let lastSequenceNumber: number = 0;
    stopPointsList.forEach((item) => {
       if (0 === item.sequenceNumber) {
           // egy rendetlen elemet talaltunk
           stopPointsListUnordered.push(item);
       } else {
           // van mar sequence number
           // berakjuk az ujrarendezett listaba
           lastSequenceNumber++;
           if (item.sequenceNumber !== lastSequenceNumber) {
               // mentes szukseges
               needToSave.value = true;
           }
           item.sequenceNumber = lastSequenceNumber;
           stopPointsListReordered.push(item);
       }
    });

    if (stopPointsListUnordered.length) {
        // mentes szukseges
        needToSave.value = true;
    }
    let stopPoint: StopPointInterface;
    // a rendezetlen elemeket az ujrarendezett listahoz adjuk
    while (stopPointsListUnordered.length) {
        stopPoint = stopPointsListUnordered.pop();
        lastSequenceNumber++;
        stopPoint.sequenceNumber = lastSequenceNumber;
        stopPointsListReordered.push(stopPoint);
    }

    shipmentStore.stopPoints = stopPointsListReordered;
});

const onStopPointDragStart = (event, stopPoint: StopPointInterface) => {
    event.dataTransfer.dropEffect = 'move';
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('stopPointId', stopPoint.id);
    event.dataTransfer.setData('stopPointSequenceNumber', stopPoint.sequenceNumber);
    draggingId.value = stopPoint.id;
};

const onStopPointDrop = (event) => {
    if (!event.target) {
        return;
    }
    let id = event.dataTransfer.getData('stopPointId');
    let sequenceNumber = event.dataTransfer.getData('stopPointSequenceNumber');
    let mouseY = event.clientY;
    // let id = event.dataTransfer.getData('packingSlipId');
    // let sequenceNumber = event.dataTransfer.getData('packingSlipSequenceNumber');
    // draggingId.value = 0;
    // meg kell allapitani, hogy ledobas helye hol van
    // az eger Y pozicioja event.clientY
    // meg kell allapitani, hogy melyik elemhez van a legkozelebb
    event.target.childNodes.forEach((item) => {
        if (item.dataset) {
            let box = item.getBoundingClientRect();
            // ha a bottom > mint a mouse, akkor kell figyelni
            // ha a top
        }
    });
};

const onStopPointDropToItem = (event) => {
    if (!event.target) {
        return;
    }
    let id = event.dataTransfer.getData('stopPointId');
    let sequenceNumber = event.dataTransfer.getData('stopPointSequenceNumber');
    // meg kell keresni a target-et
    let target = event.target;
    while (target) {
        if (target.classList.contains('drop-zone')) {
            break;
        }
        target = target.parentNode;
    }
    if (!target) {
        return;
    }
    let catcherId = target.dataset.id;
    if (id == catcherId) {
        // sajat magara dobtuk, nem kell semmit se csinalni
        return;
    }
    needToSave.value = true;
    let catcherSequenceNumber = parseInt(target.dataset.sequenceNumber);
    let box = target.getBoundingClientRect();
    draggingId.value = 0;
    // meg kell allapitani, hogy ledobas helye hol van
    // az eger Y pozicioja event.clientY
    let mouseY = event.clientY;
    let tresholdY = box.top + (box.height / 2);

    let newSequenceNumber = catcherSequenceNumber;
    if (mouseY > tresholdY) {
        // az also reszben vagyunk, tehat moge kerul
        newSequenceNumber = catcherSequenceNumber + 1;
    } else if (mouseY < tresholdY) {
        // a felso reszben vagyunk, tehat ele kerul
        // pass
    }
    shipmentStore.reOrderingStopPoint(sequenceNumber - 1, newSequenceNumber - 1)
};

const shipmentOrderingFinished = (finished: boolean = false) => {

    let ordering: {[key: string]: number} = {};

    shipmentStore.stopPoints.forEach((stopPoint) => {
        const {id, sequenceNumber} = stopPoint;
        ordering[id] = sequenceNumber;
    })

    shipmentStore.apiShipmentOrderingFinished(router, shipmentStore.shipment.id, ordering, finished)
        .then((response) => {
            let shipment = response as ShipmentInterface;
            shipmentStore.replaceShipment(shipment);
            if (ShipmentStatus.READY == shipment.status) {
                toastStore.addToast({
                    message: trans(
                        'shipment_ordering_finished',
                        'Shipment ordering finished.',
                        'shipment'
                    ),
                    icon: 'marso-icon-notification',
                    type: 'success'
                });
            } else if (ShipmentStatus.PREPARED == shipment.status) {
                toastStore.addToast({
                    message: trans(
                        'shipment_ordering_saved',
                        'Shipment ordering saved.',
                        'shipment'
                    ),
                    icon: 'marso-icon-notification',
                    type: 'success'
                });
            }
        })
        .catch((error) => {
            toastStore.addToast({
                message: trans(
                    'shipment_ordering_failed',
                    'Shipment ordering failed!',
                    'shipment'
                ),
                icon: 'marso-icon-notification',
                type: 'danger'
            });
        });
};

const getPackingSlipIds = (deliveryAddress: string) => {
    if (!shipmentStore.shipment.packingSlips.length || !deliveryAddress) return;

    const ids = shipmentStore.shipment.packingSlips
        .filter(packingSlip => {
            const address = `${packingSlip.deliveryZipCode} ${packingSlip.deliveryCity} ${packingSlip.deliveryStreet}`;
            return deliveryAddress === address;
        })
        .map(packingSlip => packingSlip.id);

    return ids.join(', ');
};

onUnmounted(() => {
    shipmentStore.stopPoints = [];
})
</script>

<style scoped>
.stop-point-enter-active, .stop-point-leave-active {
    transition: all 0.3s ease;
}
.stop-point-enter-from, .stop-point-leave-to {
    opacity: 0;
    transform: translateY(20px);
}
.stop-point-move {
    transition: transform 0.3s ease;
}
</style>
