@import "/srv/www/assets/styles/_mixins.scss";

@import "@vueup/vue-quill/dist/vue-quill.snow.css";

.ql-editor {
    min-height: 300px;

    img {
        display: inline;
    }
}
