<template>
    <transition name="fade">
        <div 
            v-if="isOpen"
            class="modal"
        >
            <div
                class="modal-content"
                :class="screenOrientationType === 'portrait-primary' ? 'flex-col-reverse' : ''"
            >
            <div
                class="buttons"
                :class="{ '!flex-row': screenOrientationType === 'portrait-primary' }"
            >
                    <button
                        type="button"
                        class="bg-red p-4 rounded"
                        @click="toggleModal"
                    >
                        <i class="marso-icon-wrong" />
                    </button>
                    <button
                        type="button"
                        class="bg-blue p-4 rounded"
                        @click="refreshCanvas"
                    >
                        <i class="marso-icon-refresh" />
                    </button>
                    <button
                        type="button"
                        class="bg-green p-4 rounded"
                        @click="sign"
                    >
                        <i class="marso-icon-right" />
                    </button>
                </div>
                <canvas
                    ref="canvas"
                    :width="canvasWidth"
                    :height="canvasHeight"
                    @mousedown="startDrawing"
                    @mousemove="draw"
                    @mouseup="endDrawing"
                    @touchstart="startDrawing"
                    @touchmove="draw"
                    @touchend="endDrawing"
                />
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, Ref, onMounted, onUnmounted } from "vue";
import { trans } from "../common/i18n";
import { useToastStore } from "../stores/toast/toastStore";

const toastStore = useToastStore();

const screenOrientationType: Ref<string> = ref("");
const canvas: Ref<HTMLCanvasElement | null> = ref(null);
const windowInnerWidth: Ref<number> = ref(0);
const windowInnerHeight: Ref<number> = ref(0);
const canvasWidth: Ref<number> = ref(0);
const canvasHeight: Ref<number> = ref(0);
const canvasPercentage: Ref<number> = ref(0.8);
const isDrawing: Ref<boolean> = ref(false);
const lastX: Ref<number> = ref(0);
const lastY: Ref<number> = ref(0); 
const savedCanvasContent: Ref<string | null> = ref(null);
const firstOrientationIsPortrait: Ref<boolean> = ref(false);
const signatureOrientationAtDrawing: Ref<string | null> = ref(null);

// eslint-disable-next-line no-unused-vars
const props = defineProps({
    isOpen: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(["toggle", "sign"]);

onMounted(() => {
    setScreenOrientationType();

    if (screenOrientationType.value === "portrait-primary") {
        firstOrientationIsPortrait.value = true;
    }

    window.screen.orientation.addEventListener('change', () => {
        setScreenOrientationType();
        updateCanvasSize();
    });

    windowInnerWidth.value = window.innerWidth;
    windowInnerHeight.value = window.innerHeight;
    
    canvasWidth.value = windowInnerWidth.value * canvasPercentage.value;
    canvasHeight.value = windowInnerHeight.value * canvasPercentage.value;
});

onUnmounted(() => {
        window.screen.orientation.addEventListener('change', () => {
        setScreenOrientationType();
        updateCanvasSize();
    });
})

const toggleModal = () => {
    emit("toggle");
};

const startDrawing = (event: MouseEvent | TouchEvent) => {
    isDrawing.value = true;
    if (!canvas.value) return;

    if (!signatureOrientationAtDrawing.value) {
        signatureOrientationAtDrawing.value = screenOrientationType.value;
    }

    if (event instanceof MouseEvent) {
        [lastX.value, lastY.value] = [event.offsetX, event.offsetY];
    } else if (event instanceof TouchEvent) {
        const touch = event.touches[0];
        [lastX.value, lastY.value] = [
            touch.clientX - canvas.value.offsetLeft,
            touch.clientY - canvas.value.offsetTop,
        ];
    }
};

const draw = (event: MouseEvent | TouchEvent) => {
    if (!isDrawing.value || !canvas.value) return;
    event.preventDefault();
    const context = canvas.value.getContext("2d");
    if (!context) return;

    context.beginPath();
    context.moveTo(lastX.value, lastY.value);

    if (event instanceof TouchEvent) {
        const touch = event.touches[0];
        [lastX.value, lastY.value] = [
            touch.clientX - canvas.value.offsetLeft,
            touch.clientY - canvas.value.offsetTop,
        ];
    } else {
        const mouseEvent = event as MouseEvent;
        [lastX.value, lastY.value] = [mouseEvent.offsetX, mouseEvent.offsetY];
    }

    context.lineTo(lastX.value, lastY.value);
    context.stroke();
};

const endDrawing = () => {
    isDrawing.value = false;
};

const refreshCanvas = () => {
    if (!canvas.value) return;
    const context = canvas.value.getContext("2d");
    if (!context) return;

    let imageData = null;

    if (canvasWidth.value && canvasHeight.value) {
        imageData = context.getImageData(0, 0, canvasWidth.value, canvasHeight.value);
    }

    if (!isCanvasEmpty(imageData)) {
        context.clearRect(0, 0, canvasWidth.value, canvasHeight.value);

        toastStore.addToast({
            title: trans('deleted_successfully', 'Deleted successfully!', 'signing_modal'),
            type: "success",
            showIcon: true,
        });
    } else {
        toastStore.addToast({
            title: trans('the_signature_field_is_empty', 'The signature field is empty!', 'signing_modal'),
            type: "danger",
            showIcon: true,
        });

        return;
    }
};

const isCanvasEmpty = (imageData: any) => {
    return !Array.from({ length: imageData.data.length / 4 }, (_, i) => i * 4).some(i => imageData.data[i + 3] !== 0);
}

const updateCanvasSize = () => {
    if (firstOrientationIsPortrait.value) {
        if (screenOrientationType.value === "portrait-primary") {
            canvasWidth.value = windowInnerWidth.value * canvasPercentage.value;
            canvasHeight.value = windowInnerHeight.value * canvasPercentage.value;
        } else {
            canvasWidth.value = windowInnerHeight.value * canvasPercentage.value;
            canvasHeight.value = windowInnerWidth.value * canvasPercentage.value;
        }
    } else {
        if (screenOrientationType.value === "landscape-primary") {
            canvasWidth.value = windowInnerWidth.value * canvasPercentage.value;
            canvasHeight.value = windowInnerHeight.value * canvasPercentage.value;
        } else {
            canvasWidth.value = windowInnerHeight.value * canvasPercentage.value;
            canvasHeight.value = windowInnerWidth.value * canvasPercentage.value;
        }
    }

    saveCanvasContent();
};

const saveCanvasContent = () => {
    if (savedCanvasContent.value && canvas.value) {
        const context = canvas.value.getContext("2d");
        if (context) {
            const img = new Image();

            img.onload = () => {
                context.clearRect(0, 0, canvasWidth.value, canvasHeight.value);
                context.save();

                if (screenOrientationType.value === "portrait-primary") {
                    context.translate(canvasWidth.value, 0);
                    context.rotate(Math.PI / 2);
                    context.imageSmoothingEnabled = false;
                    context.drawImage(img, 0, 0, canvasHeight.value, canvasWidth.value);
                } else {
                    context.translate(0, canvasHeight.value);
                    context.rotate(-Math.PI / 2);
                    context.imageSmoothingEnabled = false;
                    context.drawImage(img, 0, 0, canvasHeight.value, canvasWidth.value);
                }

                context.restore();
            };

            img.src = savedCanvasContent.value;
        }
    }
};

const sign = () => {
    if (!canvas.value) return;
    const context = canvas.value.getContext("2d");
    if (!context) return;
    const imageData = context.getImageData(0, 0, canvasWidth.value, canvasHeight.value);

    if (isCanvasEmpty(imageData)) {
        toastStore.addToast({
            title: trans('the_signature_field_is_empty', 'The signature field is empty!', 'signing_modal'),
            type: "danger",
            showIcon: true,
        });
        return;
    }

    const isPortraitToLandscape =
        signatureOrientationAtDrawing.value === "portrait-primary" &&
        screenOrientationType.value === "landscape-primary";

    const isLandscapeToPortrait =
        signatureOrientationAtDrawing.value === "landscape-primary" &&
        screenOrientationType.value === "portrait-primary";

    const isSamePortrait =
        signatureOrientationAtDrawing.value === "portrait-primary" &&
        screenOrientationType.value === "portrait-primary";

    if (isPortraitToLandscape || isLandscapeToPortrait || isSamePortrait) {
        const rotateAngle = isPortraitToLandscape ? 90 : -90;

        const tempCanvas = document.createElement("canvas");
        const tempContext = tempCanvas.getContext("2d");
        if (!tempCanvas || !tempContext) return;

        tempCanvas.width = (rotateAngle === 90) ? canvasWidth.value : canvasHeight.value;
        tempCanvas.height = (rotateAngle === 90) ? canvasHeight.value : canvasWidth.value;

        tempContext.translate(tempCanvas.width / 2, tempCanvas.height / 2);
        
        if (isSamePortrait) {
            tempContext.drawImage(canvas.value, -canvasWidth.value / 2, -canvasHeight.value / 2);
        } else {
            tempContext.rotate(rotateAngle * Math.PI / 180);
            tempContext.drawImage(canvas.value, -canvasWidth.value / 2, -canvasHeight.value / 2);
        }

        canvas.value.width = tempCanvas.width;
        canvas.value.height = tempCanvas.height;
        context.clearRect(0, 0, canvas.value.width, canvas.value.height);
        context.drawImage(tempCanvas, 0, 0);
    }

    emit("sign", canvas.value.toDataURL());
    toggleModal();

    toastStore.addToast({
        title: trans('signed_successfully', 'Signed successfully!', 'signing_modal'),
        type: "success",
        showIcon: true,
    });

    context.clearRect(0, 0, canvasWidth.value, canvasHeight.value);
    signatureOrientationAtDrawing.value = null;
};

const setScreenOrientationType = () => {
    screenOrientationType.value = window.screen.orientation.type

    if (canvas.value) {
        savedCanvasContent.value = canvas.value.toDataURL();
    }
};
</script>

<style scoped>
.modal {
    @apply bg-gray;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    z-index: 50;
    transform-origin: center center;
    width: 100%;
    height: 100%;
}

.modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 15px;
}

.buttons {
    @apply text-4xl text-white;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

canvas {
    @apply bg-white;
    border: 2px solid black;
}
</style>
