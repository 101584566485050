<template>
    <div class="2xl:container mx-auto pt-8 px-4">
        <div class="flex flex-col md:flex-row flex-wrap md:justify-between space-y-4 md:space-y-0">
            <div class="flex items-center text-3xl font-bold">
                {{ trans('shipment_preparator', 'Shipment preparator', 'preparator') }}
            </div>

        </div>

        <div class="grid min-h-inherit">
            <div class="self-center justify-self-center">
                <PackingSlipsLoaderForm @submit="handleFormSubmit"></PackingSlipsLoaderForm>
            </div>
        </div>

    </div>
</template>

<script setup lang="ts">

import {useShipmentStore} from "../../stores/shipment/shipmentStore";
import {useSecurityStore} from "../../stores/security/securityStore";
import {useRouter} from 'vue-router';
import {useToastStore} from "../../stores/toast/toastStore";
import PackingSlipsLoaderForm from '../../components/PackingSlipsLoaderForm.vue';
import {trans} from "../../common/i18n";

//LOCAL VARIABLES
const shipmentStore = useShipmentStore();
const securityStore = useSecurityStore();
const toastStore = useToastStore();
const router = useRouter();

//ACTIONS
const handleFormSubmit = (params: {axaptaShipmentId: string, packingSlipId: string}) => {
    shipmentStore.apiShipmentCreateFromAxaptaPackingSlips(router, params)
        .then((shipment) => {
            toastStore.addToast({
                message: trans(
                    'shipment_created_successfully',
                    'Shipment created successfully!',
                    'shipment'
                ),
                type: 'success',
            });
            router.push({ name: 'shipment_view_edit', params: { id: shipment.id } });
        })
        .catch((error) => {
            console.log(error);
            // toastStore.addToast({
            //     message: error,
            //     type: "danger",
            // });
        });
};

</script>
<style>
.min-h-inherit {
    min-height: inherit;
}
</style>
