@import "/srv/www/assets/styles/_mixins.scss";

.modal-loading {
    .icon {
        // tailwind sizes not big enough
        font-size: 10rem;
        animation: spin 3s linear infinite;
        display: block;
    }
}
