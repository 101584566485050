@import "/srv/www/assets/styles/_mixins.scss";
@import 'marso-font/style.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* stylelint-disable-next-line no-invalid-position-at-import-rule */
    @import 'layer_base';
}

@layer components {
    /* stylelint-disable-next-line no-invalid-position-at-import-rule */
    @import 'layer_components';
}

@layer utilities {
    /* stylelint-disable-next-line no-invalid-position-at-import-rule */
    @import 'layer_utilities';
}
